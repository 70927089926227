@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	breadcrumb__list
/--------------------------------------------------------------------*/
.breadcrumb__list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 0.9em;
  @include media(pc_s) {
    margin-bottom: 4rem;
  }
}
.breadcrumb__list .icom-home {
  font-size: 1.2em;
}
.breadcrumb__list a {
  color: $txt_c;
  @include transition;
  vertical-align: top;
  &:hover {
    color: $main_c;
  }
}

.cont__inner .breadcrumb__list a,
.works .breadcrumb__list a {
  color: $white;
  &:hover {
    color: $main_c;
  }
}
