@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	works
/--------------------------------------------------------------------*/

/* works */
.works {
	color: $white;
	padding: 0;
	width: 100%;
	position: relative;
	background: transparent;
	&::before {
		background: url(../img/bg_02_pc.jpg) 50% top no-repeat;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: -1;
	}
	& .l-cont {
		position: relative;
	}
	&__wrap {
		@include media(pc) {
			@include m-a;
		}
	}
}

#works .btn__wrap {
	margin-left: auto;
	margin-right: auto;
	margin-top: 3rem;
	max-width: 400px;
}

.works-detail {
	margin-bottom: 3rem;
	@include media(pc_s) {
		margin-bottom: 6rem;
	}
	&__tit {
		font-size: calc(1.6rem + 4 * (100vw - 320px) / 680);
		font-weight: 400;
		margin-bottom: 1.5rem;
		border-bottom: 1px solid $l-gray;
		padding-bottom: 0.5rem;
		@include media(pc) {
			@include fz(20);
		}
	}
	&__cont {
		margin-bottom: 1.5rem;
	}
	&__slider {
		margin: 0 auto;
		text-align: left;
	}
	&__txt {
		padding-top: 1rem;
		padding-bottom: 1rem;
		@include media(tb) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
		.txt {
			background: $p-gray;
			padding: 2rem 3rem;
		}
	}
}

.works-detail__slider {
	display: flex;
	width: 100%;
	@include media(pc_s) {
		justify-content: space-between;
	}
	@include media(tb_less) {
		flex-direction: column;
	}
}

.gallery-top {
	width: 100%;
	text-align: left;
	position: relative;
	overflow-x: hidden;
	@include media(pc_s) {
		width: 75%;
	}
	@include media(tb_less) {
		margin: 0 auto;
	}
	ul {
		top: 0;
		left: 0;
		width: 100%;
		position: absolute;
		li {
			width: 100%;
			float: left;
			display: inline;
			overflow-x: hidden;
		}
	}
	figcaption {
		max-height: 6rem;
		overflow-y: auto;
		@include media(pc_s) {
			max-height: 8rem;
			position: absolute;
			background: $black;
			color: $white;
			display: inline-block;
			padding: 0.2rem 1rem;
			bottom: 0;
			left: 0;
		}
	}
	&__img {
		background-color: $b-gray;
		@include aspect-img();
		img {
			@include m-a;
			display: block;
			width: 100%;
		}
	}
}
.gallery-thumbs {
	width: 100%;
	overflow: hidden;
	@include media(pc_s) {
		width: 20%;
	}
	@include media(tb_less) {
		margin: 0 auto 1rem;
	}
	ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		@include media(pc_s) {
			column-gap: 10%;
		}
		@include media(tb_less) {
			column-gap: 1.66%;
		}		
		li {
			cursor: pointer;
			margin-bottom: 1rem;
			overflow: hidden;
			@include media(pc_s) {
				margin-bottom: 1.6rem;
				width: 45%;
			}
			@include media(tb_less) {
				width: 15.27%;
			}
			img {
				width: 100%;
			}
			a {
				opacity: 0.7;
				&.active {
					opacity: 1 !important;
				}
			}
		}
	}
}

.works-detail__main {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 1rem;
	@include media(pc_s) {
		margin-bottom: 2rem;
	}
	&__img {
		@include aspect-img();
		img {
			@include m-a;
			display: block;
			width: 100%;
		}
	}
}



//PDF 大枠
.pdf-cont {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include media(tb) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

//動画
.works-video {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include media(tb) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	&__tit {
		font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
		text-align: center;
		@include f-w(600);
		margin-bottom: 2rem;
		@include media(pc) {
			@include fz(18);
		}
	}
	&__flex {
		@include media(pc_s) {
			@include flex-between;
		}
	}
	&__video {
		@include media(pc_s) {
			width: 37.5%;
		}
		@include media(tb_less) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media(pc_s) {
			width: 58%;
		}
	}
}

//概要
.works-outline {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include media(tb) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	&__tit {
		font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
		text-align: center;
		@include f-w(600);
		margin-bottom: 2rem;
		@include media(pc) {
			@include fz(18);
		}
	}
	&__table {
		border-top: 1px solid $l-gray;
		border-left: 1px solid $l-gray;
		width: 100%;
		@include media(pc_s) {
			display: table;
			width: 100%;
		}
		& th,
		& td {
			padding: 1rem 1.5rem;
			text-align: left;
			vertical-align: top;
			font-weight: normal;
			display: block;
			width: auto;
			border-right: 1px solid $l-gray;
			border-bottom: 1px solid $l-gray;
			@include media(pc_s) {
				padding: 1.5rem 2.5rem;
				display: table-cell;
				width: auto;
				vertical-align: top;
				text-align: left;
			}
		}
		& th {
			@include f-w(600);
			@include media(pc_s) {
				width: 30%;
			}
		}
		& td {
			@include media(pc_s) {
				width: auto;
			}
		}
	}
}

//アクセスマップ
.works-access {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include media(tb) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	&__tit {
		font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
		text-align: center;
		@include f-w(600);
		margin-bottom: 2rem;
		@include media(pc) {
			@include fz(18);
		}
	}
	&__map {
		margin-bottom: 1rem;
		position: relative;
		padding: 0;
		height: 0;
		overflow: hidden;
		background-color: $white;
		@include media(pc) {
			padding-bottom: 40%;
		}
		@include media(pc_less) {
			padding-bottom: 300px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}
