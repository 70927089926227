@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/* CSS Document */
/*====================================================================

lv2.css

=====================================================================*/

@use "_lv2/breadcrumb"; //パンクズ
@use "_lv2/contact"; //お問い合わせ
@use "_lv2/works"; //実績紹介
@use "_lv2/blog"; //ブログ
@use "_plugin/bxslider"; //bxslider

/*--------------------------------------------------------------------/
	404
/--------------------------------------------------------------------*/

.not-found {
	background: transparent;
	position: relative;
	width: 100%;
	color: $white;
	padding: 0;
	width: 100%;
	position: relative;
	&::before {
		background: url(../img/bg_01_pc.jpg) 50% top no-repeat;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: -1;
	}
	.l-cont {
		position: relative;
	}
	h3 {
		@include f-s_sp(1.6, 2);
		margin-bottom: 1.5rem;
		@include media(pc) {
			@include fz(18);
		}
	}
}
